import { useQueryClient } from '@tanstack/vue-query'

export interface UseUnlinkHandlerOptions<S extends boolean = false> {
  title?: string
  body?: string
  loadingMessage?: string
  successMessage?: string
  errorMessage?: string
  confirmInputText?: string
  isSublease?: S
}

type UnlinkDocument = ReturnType<
  typeof useApiLeaseUnlinkDocument
>['mutateAsync']
export type UnlinkDocumentVariables = Parameters<UnlinkDocument>[0]
type UnlinkSublease = ReturnType<
  typeof useApiLeaseUnlinkSublease
>['mutateAsync']
export type UnlinkSubleaseVariables = Parameters<UnlinkSublease>[0]
type Unlink = UnlinkDocument | UnlinkSublease
type UnlinkVariables = UnlinkDocumentVariables | UnlinkSubleaseVariables

export const useUnlinkHandler = <S extends boolean = false>(
  options: UseUnlinkHandlerOptions<S> = {},
) => {
  const {
    title = 'Unlink amendment',
    body = 'Are you sure you want to unlink this amendment?',
    loadingMessage = 'Unlinking amendment...',
    successMessage = 'Amendment unlinked',
    errorMessage = 'Failed to unlink amendment',
    confirmInputText = 'UNLINK',
    isSublease = false,
  } = options

  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  const queryClient = useQueryClient()

  let unlink: Unlink

  if (isSublease) {
    const { mutateAsync } = useApiLeaseUnlinkSublease(divisionId)
    unlink = mutateAsync
  } else {
    const { mutateAsync } = useApiLeaseUnlinkDocument(divisionId, leaseId)
    unlink = mutateAsync
  }

  return useConfirmHandler<
    S extends true ? UnlinkSubleaseVariables : UnlinkDocumentVariables
  >(
    (variables) =>
      unlink(variables as UnlinkDocumentVariables & UnlinkSubleaseVariables),
    {
      modal: {
        title,
        body,
        confirmInputText,
      },
      loadingMessage,
      successMessage,
      errorMessage,
      submitHandlerOptions: {
        beforeSubmit({ data }) {
          if (!isSublease && !(data as UnlinkDocumentVariables).documentId)
            return false
        },
      },
    },
  )
}
