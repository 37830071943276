import { useMutation, useQueryClient } from '@tanstack/vue-query'

export interface ILeaseUnlinkSubleseBody {
  subleaseId: string
  parentId: string
}

export const useApiLeaseUnlinkSublease = (companyId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ subleaseId }: ILeaseUnlinkSubleseBody) =>
      fetchAPI<undefined>(
        `/companies/${companyId}/leaseRegister/leases/${subleaseId}`,
        {
          method: 'PATCH',
          body: {
            parent: {
              parentId: null,
            },
          },
        },
      ),

    onSuccess: async (data, { subleaseId, parentId }) => {
      queryClient.setQueryData(
        ['getLeaseDocumentId', companyId, subleaseId],
        data,
      )
      await queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', subleaseId],
        exact: true,
      })
      // when unlinking from parent lease page, invalidate all subleases
      await queryClient.invalidateQueries({
        queryKey: [
          'companies',
          companyId,
          'leases',
          parentId,
          'documents',
          'subleases',
        ],
      })

      queryClient.invalidateQueries({
        queryKey: ['getLeaseDocumentId', companyId, parentId],
        exact: true,
      })
    },
  })
}
