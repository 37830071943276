import { useMutation, useQueryClient } from '@tanstack/vue-query'

export const useApiLeaseUnlinkDocument = (
  companyId: string,
  leaseId: string,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ documentId }: { documentId: string }) =>
      fetchAPI<undefined>(`/companies/${companyId}/documents/${documentId}`, {
        method: 'DELETE',
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', leaseId, 'documents'],
      })

      await queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', leaseId],
        exact: true,
      })

      queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', leaseId, 'fields'],
      })

      queryClient.invalidateQueries({
        queryKey: getApiLeaseSummaryKey(companyId, leaseId),
      })

      queryClient.invalidateQueries({
        queryKey: ['getLeaseDocumentId', companyId, leaseId],
        exact: true,
      })
    },
  })
}
